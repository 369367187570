<template>
  <div class="chat_div">
    <div class="chat_ln">
      <div class="left" v-if="false">
        <div class="title">切换写作风格</div>
        <div
          class="list"
          :class="{ active: active == index }"
          @click="active = index"
          v-if="index < isGs"
          v-for="(item, index) in list"
          :key="index"
        >
          {{ item }}
        </div>
        <div class="gdsl" @click="isGs == 5 ? (isGs = 100) : (isGs = 5)">
         
          <i
            :class="isGs == 5 ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"
          ></i>
        </div>
      </div>
      <div class="right">
        <div class="chat_ln_2"  ref="mianscroll">
            <div class="appTitle_box" v-if="appTitle!='稿件润色'&&appTitle!='定位Bug'">
                <router-link class="router" :to="{path:'/index'}">
                    <i class="el-icon-arrow-left"></i>
                </router-link>
                <div class="title">{{appTitle}}</div>
            </div>
          <div class="list" v-for="(item, index) in listQuery" :key="index" >
            <template v-if="item.divider">
                <div class="divider"><div class="text">对话已过期，开始新对话</div></div>
            </template>
            <template v-else>
                <div class="list_top" v-if="item.woTitle">
                  <div class="name" v-if="!isMobile">{{account.username['0']}}</div>
                  <div class="text"  v-html="item.woTitle.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp')" >
                </div>
                </div>
                <div class="list_bottom_b"> 
                   <div class="list_bottom">
                    <div class="fz_button" @click="onCopy(index)" v-if="!isMobile">一键复制</div>
                  <div class="name" v-if="!isMobile">AI</div>
          
                  <mavon-editor
                  v-if="item.taTitle"
                  class="md_box"
                      :value="item.taTitle"
                      :subfield="prop.subfield" 
                      :defaultOpen="prop.defaultOpen"
                      :toolbarsFlag="prop.toolbarsFlag"
                      :editable="prop.editable"
                      :scrollStyle="prop.scrollStyle"
                      />
                  
                    <div class="loadingThree" v-else>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                      
                  
                </div>
                
                </div>
               
                <!-- <div class="appraise_box" v-if="!item.isEvaluate" v-show="isAsk">
                    <div class="title">以上结果是否满意？ </div>
                    <div class="boxs">
                        <div class="box" @click="getEngineFeedback(2,'',item.woTitle,item.taTitle,index)">
                            <img src="@/assets/images/appraise1.png" class="img" />
                            好评
                        </div>
                        <div class="box" @click="getEngineFeedback(1,'',item.woTitle,item.taTitle,index)">
                            <img src="@/assets/images/appraise2.png" class="img" />
                            中评
                        </div>
                        <el-popover
                            placement="bottom"
                            trigger="click">
                            <div class="box" slot="reference">
                                <img src="@/assets/images/appraise3.png" class="img" />
                                差评
                            </div>
                            <div class="common_button">
                                <div class="button_div" @click="getEngineFeedback(0,'事实错误',item.woTitle,item.taTitle,index)">事实错误</div>
                                <div class="button_div" @click="getEngineFeedback(0,'信息有害',item.woTitle,item.taTitle,index)">信息有害</div>
                                <div class="button_div" @click="getEngineFeedback(0,'无意义灌水',item.woTitle,item.taTitle,index)">无意义灌水</div>
                                <div class="button_div" @click="getEngineFeedback(0,'其他',item.woTitle,item.taTitle,index)">其他</div>
                            </div>
                        </el-popover>
                        
                    </div>
                </div> -->
            </template>
            
          </div>
        </div>
      </div>
      <div class="chat_ln_3_fixed">
        <div class="buts" >
            <el-button :disabled="isListQueryEmpty||isLoading" icon="el-icon-delete" @click="emptyFun(true)">重新开始</el-button>
            <!-- <el-button :disabled="isListQueryEmpty||isLoading" icon="el-icon-refresh-right" @click="oncemore()">换个回答</el-button>
            <el-button :disabled="isListQueryEmpty||isLoading" icon="el-icon-document-checked" @click="getEngineSavedialog()">保存对话</el-button> -->
          
        </div>
       
        <div class="chat_ln_3">
          <div class="chat-input-small">
            <div class="chat-input-textarea">
              <textarea
                ref="test"
                class="textarea_input"
                :value="param.query"
                @input="handleQueryInput"
                @keydown.enter="exactA"
                placeholder="告诉AI你想要它生成的内容"
              ></textarea>
              <div
                class="textarea_ghost"
                v-html="formatTextToHtml(param.query)"
              ></div>
            </div>
            <div class="chat-input-button">
              <div class="chat-input-button-inner">
                <div class="button_box">
                  <img
                    class="fs_button"
                    @click="getEngineQuery()"
                    src="@/assets/images/new/enter.svg"
                  />
                </div>
              </div>
            </div>
            <div class="ms">Shift+Enter输入框内换行</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { engineQuery,payAccountcheck,logout,engineFeedback,engineSavedialog,getredbook,getseomarketing,getforeignnews,getcodedebug,getpolish,getmakeplan,getdouyin,getdivine} from "@/api/user";
import { mavonEditor } from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import axios from "axios";

// var t = document.getElementById("left");
// t.style.visibility = 'hidden'
// document.getElementById("left").style.visibility="hidden"
export default {
    components:{// 在页面中注册组件
        mavonEditor 
    },
  data() {
    return {
      active: 0,
      list: [
        "无风格(默认)",
        "小学生",
        "科技文",
        "猫猫语",
        "跨语言",
        "其他自己放",
        "其他自己放",
        "其他自己放",
        "其他自己放",
        "其他自己放",
        "其他自己放",
        "其他自己放",
        "其他自己放",
      ],
      isGs: 5,
      value: "",

      engineQuery: [],
      param: {
        select_style: "无风格（默认）",
        select_lang: "简体中文（默认）",
        query: "",
        ask_again:0,
        new_session: 1,
      },
      historychat:{
        woTitle:[],
        taTitle:[]

      },
      listQuery: [],
      isAsk: true,
      isLoading:false,//是否正在生成
      
      aiAnswer:'',
        account:{},
        isMobile:"",
        timer:null,
        thisData:0,
        lastquery:"",
        //会话过期
        timerSession:null,
        thisDataSession:0,
        appTitle:"",
    };
  },
  computed: {
    isListQueryEmpty(){
      var that = this;
      return that.listQuery.length ===0;

    },
    prop () {
      let data = {
        subfield: false,// 单双栏模式
        defaultOpen: 'preview',//edit： 默认展示编辑区域 ， preview： 默认展示预览区域 
        editable: false,    // 是否允许编辑
        toolbarsFlag: false,
        scrollStyle: true
      }
      return data
    }
  },
  watch: {
    "$route"(nv, ov) {
        var that = this;
        that.appTitle=that.$route.query.appTitle;
        that.getPayAccountcheck()
    },
  },
  mounted() {
    var that = this;
    that.isMobile=navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    // that.param.query = localStorage.getItem("chatValue");
    that.getPayAccountcheck();
    that.appTitle=that.$route.query.appTitle;
    
  },
  methods: {
    // 保存对话
    getEngineSavedialog(){
      var that = this;
      engineSavedialog().then((res) => {
                if(res.code==0){
                    that.$message.success("保存成功");
                }
            });

    },
    getEngineFeedback(rate,reason,query,answer,index){
        var that = this;
        var param={
            "rate":rate,
            "query":query,
            "answer":answer,
            "session_id":that.param.session_id,
        }
        if(reason){
            param.reason=reason
        }
        engineFeedback(param).then((res) => {
            if(res.code==0){
                that.$message.success("收到！积分+1");
                that.$set(that.listQuery[index],"isEvaluate",true)
            }
        });
    },
    guid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

     // 再问一遍
    oncemore(){
      var that = this;
      that.listQuery[that.listQuery.length - 1].isEvaluate =false
      //判断是否过期
      if(!that.timerSession){
        return false;
      }
      that.getmoreQuery( that.lastquery)
    },
    
    emptyFun(is){
        var that = this;
        let scrollEl = this.$refs.mianscroll;
        if(is){
           
            that.listQuery=[];
            that.param= {
                select_style: "无风格（默认）",
                select_lang: "简体中文（默认）",
                query: "",
                new_session: 1,
            }
            that.param.session_id=that.guid();
            // //是否多轮
            // if(that.account.multi_turn==1){
            //     that.param.session_id=that.guid();
            // }
            clearInterval(that.timerSession);
            that.timerSession=null;
            scrollEl.scrollTo({ top: 0, behavior: "instant" });
            that.getGetquery();
                
        }else{
            that.listQuery=[];
            that.param= {
                select_style: "无风格（默认）",
                select_lang: "简体中文（默认）",
                query: "",
                new_session: 1,
            }
            that.param.session_id=that.guid();
            // //是否多轮
            // if(that.account.multi_turn==1){
            //     that.param.session_id=that.guid();
            // }
            clearInterval(that.timerSession);
            that.timerSession=null;
            scrollEl.scrollTo({ top: 0, behavior: "instant" });
        }
    },
    //清空会话
    // emptyFun(is){
    //     var that = this;
    //     let scrollEl = this.$refs.mianscroll;
    //     if(is){
    //         that.$confirm('是否重新开始', '提示', {
    //             confirmButtonText: '确定',
    //             cancelButtonText: '取消',
    //             type: 'warning'
    //             }).then(() => {
    //                 that.listQuery=[];
    //                 that.param= {
    //                     select_style: "无风格（默认）",
    //                     select_lang: "简体中文（默认）",
    //                     query: "",
    //                     new_session: 1,
    //                 }
    //                 //是否多轮
    //                 if(that.account.multi_turn==1){
    //                     that.param.session_id=that.guid();
    //                 }
    //                 clearInterval(that.timerSession);
    //                 that.timerSession=null;
    //                 scrollEl.scrollTo({ top: 0, behavior: "instant" });
    //                 that.getGetquery();
    //             }).catch(() => {
    //         });
    //     }else{
    //         that.listQuery=[];
    //         that.param= {
    //             select_style: "无风格（默认）",
    //             select_lang: "简体中文（默认）",
    //             query: "",
    //             new_session: 1,
    //         }
    //         //是否多轮
    //         if(that.account.multi_turn==1){
    //             that.param.session_id=that.guid();
    //         }
    //         clearInterval(that.timerSession);
    //         that.timerSession=null;
    //         scrollEl.scrollTo({ top: 0, behavior: "instant" });
    //     }
    // },
    //复制
    onCopy(index) {
        var that = this;
      that.$copyText(that.listQuery[index].taTitle).then(
	      e=>{
	        that.$message.success("复制成功");
	      },
	      e=>{
            that.$message.error("复制失败");
	      }
      )
    },
    exactA(e) {
      var that = this;
      if(e.keyCode == 13 && e.ctrlKey){
        that.param.query += "\n"; //换行
      }else if(e.keyCode == 13 && e.shiftKey){
        that.value += "\n"; //换行
      }else if (e.keyCode == 13) {
        that.getEngineQuery();
        e.preventDefault();//禁止回车的默认换行
      }
    },
    getEngineQuery() {
      
      var that = this;
      that.isLoading = true;
      if (that.param.query) {
        that.lastquery = that.param.query
        if (!that.isAsk) {
          return false;
        }
        let scrollEl = this.$refs.mianscroll;
        that.isAsk = false;
        that.listQuery.push({
          woTitle: that.param.query,
          taTitle: "",
        });
        
        
        var param=JSON.parse(JSON.stringify(that.param))
        that.param.query="";
        

        that.$nextTick(function(){
            scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
        })
        that.thisDataSession=0;
        that.thisData=0
        that.timer=window.setInterval(function(){
            if(that.thisData>=1800){
                that.isAsk = true
                that.listQuery[that.listQuery.length - 1].taTitle+='\n【系统提示：请求超时，请重试】';
                that.listQuery[that.listQuery.length - 1].isEvaluate=true;
                clearInterval(that.timer);
            }else{
                that.thisData++;
                console.log(that.thisData)
            }
        },1000);

      
        const apptitletrans = {'列计划':'makeplan',"短视频文案":'douyin'
                        ,'新闻稿':'foreignnews','定位Bug':'codedebug',
                        '营销文案':'seomarketing','稿件润色':'polish','小红书':'redbook','算命':'divine'}

        const url = `${'api/'}${apptitletrans[that.appTitle]}${'/setreply'}`;
        // console.log("url:",url)
        // console.log("param:",param)


        axios({
          url:url,
          method: "post",
          responseType:"stream",
          headers: {
            "Content-Type": "application/json;",
          },
          data: param,
          onDownloadProgress: function (res) {
           
            that.thisData=1;
            that.$nextTick(function(){
                scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
            })

  
             that.listQuery[that.listQuery.length - 1].taTitle =
            res.currentTarget.response;
            // console.log(that.listQuery[that.listQuery.length - 1].taTitle)

          },
          
         
        }).then((res) => {
            clearInterval(that.timer);
            //是否多轮
            console.log("计时器的状态",that.timerSession)
            if(that.account.multi_turn==1&&!that.timerSession){
                that.timerSession=window.setInterval(function(){
                    if(that.thisDataSession>=900){
                        that.listQuery.push({"divider":true});
                        that.param.new_session=1;
                        that.param.session_id=that.guid();
                        that.$nextTick(function(){
                            scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
                        })
                        clearInterval(that.timerSession);
                        that.timerSession=null;
                    }else{
                        that.thisDataSession++;
                        console.log(that.thisDataSession)
                    }
                },1000);
            }
            if(typeof(res.data)=="object"){
                that.listQuery[that.listQuery.length - 1].isEvaluate=true;
            }
            if(res.data.code==405||res.data.code==10){
                that.$message.error(res.data.result.message);
                that.listQuery[that.listQuery.length - 1].taTitle=res.data.result.message
            }
            // else if(res.data.code==30){
            //     that.listQuery[that.listQuery.length - 1].taTitle=res.data.result.message
            //     that.$confirm(res.data.result.message, '提示', {
            //         confirmButtonText: '确定',
            //         cancelButtonText: '取消',
            //         type: 'warning'
            //         }).then(() => {
            //             that.emptyFun();//清楚
            //         }).catch(() => {
            //     });
            //     that.isAsk = true;
            //     return false;
            // }
            else if(res.data.code==40){
                that.listQuery[that.listQuery.length - 1].taTitle=res.data.result.message
                that.$confirm(res.data.result.message, '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        //去登录
                       that.listQuery.push({"divider":true});
                        that.param.new_session=1;
                        that.param.session_id=that.guid();
                        that.getEngineQuery()
                        // logout().then((res) => {
                        //     if(res.code==0){
                        //         that.$router.push({ path: "/login" });
                        //     }
                        // });
                    }
                });
                that.isAsk = true;
                
                return false;
            }
            that.isAsk = true;
            that.$nextTick(function(){
                scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
            })
            that.param.new_session=0;
            that.isLoading = false;
            // that.lastquery = that.listQuery[that.listQuery.length - 1].woTitle
        }).catch((error) => {
            clearInterval(that.timer);
            that.isAsk = true;
            if(error.response.status==401){
                that.$router.push({
                    path: "/login",
                });
            }
        }
       
        );
      }
    },

    // 换个回答

     getmoreQuery(query) {
      var that = this;
      // that.param = {
      //   query:query,
      //   ask_again:1,
      //   new_session: 0,
      // };
      
      
       
      if (!that.isAsk) {
        return false;
      }
      that.param.query = query
      that.param.ask_again = 1
      let scrollEl = this.$refs.mianscroll;
      that.isAsk = false;
       
      var param=JSON.parse(JSON.stringify(that.param))
      that.param.query="";

      that.$nextTick(function(){
          scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
      })
      that.thisDataSession=0;
      that.thisData=0
      that.timer=window.setInterval(function(){
            if(that.thisData>=1800){
                that.isAsk = true
                that.listQuery[that.listQuery.length - 1].taTitle+='\n【系统提示：请求超时，请重试】';
                that.listQuery[that.listQuery.length - 1].isEvaluate=true;
                clearInterval(that.timer);
            }else{
                that.thisData++;
            }
        },1000);

      axios({
        url: "api/redbook/setreply",
        method: "post",
        responseType:"stream",
        headers: {
          "Content-Type": "application/json;",
        },
        data: param,
        onDownloadProgress: function (res) {
          that.isLoading = true;
          that.$nextTick(function(){
              scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
          })
          // console.log("is_askagain:",that.isask_again)


          that.listQuery[that.listQuery.length - 1].taTitle =
          res.currentTarget.response;

        },
          
         
        }).then((res) => {
            clearInterval(that.timer);
            //是否多轮
            console.log("计时器的状态",that.timerSession)
            if(that.account.multi_turn==1&&!that.timerSession){
                that.timerSession=window.setInterval(function(){
                    if(that.thisDataSession>=900){
                        that.listQuery.push({"divider":true});
                        that.param.new_session=1;
                        that.param.session_id=that.guid();
                        that.$nextTick(function(){
                            scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
                        })
                        clearInterval(that.timerSession);
                        that.timerSession=null;
                    }else{
                        that.thisDataSession++;
                        console.log(that.thisDataSession)
                    }
                },1000);
            }
            if(typeof(res.data)=="object"){
                that.listQuery[that.listQuery.length - 1].isEvaluate=true;
            }
            if(res.data.code==405||res.data.code==10){
                that.$message.error(res.data.result.message);
                that.listQuery[that.listQuery.length - 1].taTitle=res.data.result.message
            }
            // else if(res.data.code==30){
            //     that.listQuery[that.listQuery.length - 1].taTitle=res.data.result.message
            //     that.$confirm(res.data.result.message, '提示', {
            //         confirmButtonText: '确定',
            //         cancelButtonText: '取消',
            //         type: 'warning'
            //         }).then(() => {
            //             that.emptyFun();//清楚
            //         }).catch(() => {
            //     });
            //     that.isAsk = true;
            //     return false;
            // }
            else if(res.data.code==40){
                that.listQuery[that.listQuery.length - 1].taTitle=res.data.result.message
                that.$confirm(res.data.result.message, '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        //去登录
                       that.listQuery.push({"divider":true});
                        that.param.new_session=1;
                        that.param.session_id=that.guid();
                        that.getEngineQuery()
                        // logout().then((res) => {
                        //     if(res.code==0){
                        //         that.$router.push({ path: "/login" });
                        //     }
                        // });
                    }
                });
                that.isAsk = true;
                
                return false;
            }
            that.isAsk = true;
            that.$nextTick(function(){
                scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
            })
            // console.log(res)
            
            that.param.new_session=0;
             that.isLoading = false;
            that.param.ask_again = 0;
             
            // that.lastquery = that.listQuery[that.listQuery.length - 1].woTitle
        }).catch((error) => {
            clearInterval(that.timer);
            that.isAsk = true;
            if(error.response.status==401){
                that.$router.push({
                    path: "/login",
                });
            }
        }
       
        );
      
    },
    getPayAccountcheck(){
        var that = this;
        payAccountcheck().then((res) => {
            if(res.code==0){
                that.account=res.result;
                that.param.session_id=that.guid();
                that.param.new_session = 1;
                // //是否多轮
                // if(that.account.multi_turn==1){
                //     that.param.session_id=that.guid();
                // }
                // if (that.param.query) {
                    // localStorage.setItem("chatValue","");
                    // that.getEngineQuery();
                    that.getGetquery();
                // }
            }
        });
    },
    getGetquery(){
        var that = this;
        if(that.appTitle=='小红书'){

            getredbook().then((res) => {
            if(res.code==0){
                that.listQuery=[{
                    woTitle: "",
                    taTitle: res.result.query,
                    isEvaluate:true
                }];
               
            }
        })
        }if(that.appTitle=='稿件润色'){
           getpolish().then((res) => {
            if(res.code==0){
                that.listQuery=[{
                    woTitle: "",
                    taTitle: res.result.query,
                    isEvaluate:true
                }];
                console.log('稿件润色',res.result.query)
               
            }
        })}if(that.appTitle=='营销文案'){
           getseomarketing().then((res) => {
            if(res.code==0){
               console.log('营销文案',res.result.query)
                that.listQuery=[{
                    woTitle: "",
                    taTitle: res.result.query,
                    isEvaluate:true
                }];
                
               
            }
        })}if(that.appTitle=='定位Bug'){
           getcodedebug().then((res) => {
            if(res.code==0){
               console.log('定位Bug',res.result.query)
                that.listQuery=[{
                    woTitle: "",
                    taTitle: res.result.query,
                    isEvaluate:true
                }];
               
            }
        })}if(that.appTitle=='新闻稿'){
           getforeignnews().then((res) => {
            if(res.code==0){
               console.log('新闻稿',res.result.query)
                that.listQuery=[{
                    woTitle: "",
                    taTitle: res.result.query,
                    isEvaluate:true
                }];
               
            }
        })}if(that.appTitle=='短视频文案'){
           
           getdouyin().then((res) => {
            if(res.code==0){
              // console.log('短视频文案',res.result.query)
                that.listQuery=[{
                    woTitle: "",
                    taTitle: res.result.query,
                    isEvaluate:true
                }];
               
            }
         })}if(that.appTitle=='算命'){
           
           getdivine().then((res) => {
            if(res.code==0){
              // console.log('短视频文案',res.result.query)
                that.listQuery=[{
                    woTitle: "",
                    taTitle: res.result.query,
                    isEvaluate:true
                }];
               
            }
        })}if(that.appTitle=='列计划'){
           
           getmakeplan().then((res) => {
            if(res.code==0){
              // console.log('短视频文案',res.result.query)
                that.listQuery=[{
                    woTitle: "",
                    taTitle: res.result.query,
                    isEvaluate:true
                }];
               
            }
        })}
      
      
       
    },
    handleCommandA(command) {
      var that = this;
      that.param.select_style = command;
    },
    handleCommandB(command) {
      var that = this;
      that.param.select_lang = command;
    },
    handleQueryInput(e) {
      this.param.query = e.target.value;
    },
    formatTextToHtml(value) {
      return value
        .replace(/(\n|\r\n)$/, "<br>&nbsp;")
        .replace(/\n|\r\n/g, "<br>")
        .replace(/ /g, "&nbsp;");
    },
    // toSaveChat(){
    //   //   var that = this;
    //   //   list = that.$refs.list.innerHTML
    //   //   console.log(list)
    //   //   that.$copyText(that.listQuery[index].taTitle).then(
	  //   //   e=>{
	  //   //     that.$message.success("保存成功");
	  //   //   },
	  //   //   e=>{
    //   //       that.$message.error("保存失败");
	  //   //   }
    //   // )
    // },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-button:focus, 
::v-deep .el-button:hover {
  // background-color: rgb(216, 158, 33) !important;
  // border-color: blue !important;
  
  // color: green;

 
}
::v-deep .el-button{
  font-size: 14px;
  padding: 10px 14px;
  margin: 0 18px;
}


// /* 修改文字颜色 */
// ::v-deep .el-button span {
//   color: green;
// }
</style>
